<template>
  <div class="web-pay">
    <div class="header">
      <img @click="goCourseDetails" class="back" src="../../assets/back-icon.png" alt="" />
      <div @click="goCourseDetails">订单支付</div>
    </div>
    <div class="content" v-if="countdown !== null">
      <div class="content-words">{{ countdownSecond > 0 ? '距离二维码过期还剩' : '二维码已过期' }}</div>
      <button class="content-refresh" v-if="countdownSecond <= 0" @click="onRefreshCode" v-preventReClick="2500">刷新二维码</button>
      <div class="content-time" v-if="countdownSecond > 0">
        <span>{{ countdownSecond }}</span>
        秒
      </div>
      <!-- 支付二维码 -->
      <div class="content-code">
        <QrCode :id="'QrCode'" :text="codeUrl" :width="'181'" :height="'180'" />
      </div>
      <!-- 过期的二维码图片 -->
      <div class="content-refresh-code" v-if="countdownSecond <= 0">已过期</div>
      <template v-if="countdownSecond <= 0">
        <div class="content-hint" style="margin-top: 28px">订单支付失败</div>
        <div class="content-hint" style="margin-top: 10px">您可以刷新二维码重新完成支付</div>
        <div class="content-hint" style="margin-top: 10px">
          或在“
          <span>我的订单</span>
          ”完成支付
        </div>
      </template>
      <template v-else>
        <div class="content-hint" style="margin-top: 28px">请使用{{ payWay == '0' ? '支付宝' : '微信' }}扫码支付</div>
        <div class="content-hint" style="margin-top: 10px">过期后请刷新页面重新获取二维码</div>
      </template>
      <button class="content-cancle" @click="onCancel" v-preventReClick="2500">取消支付</button>
    </div>
    <div class="msg" v-else>{{ msg }}</div>
  </div>
</template>
<script>
import { orderPayV2, checkPay } from '@/api/confirmOrder.js'
export default {
  name: 'WebPay',
  data() {
    return {
      orderNo: this.$route.query.orderNo, //订单号
      orderId: this.$route.query.orderId,
      payWay: this.$route.query.payWay, //微信还是支付宝
      terminalType: 'webPc', //终端型号
      countdown: null, //倒计时时长 毫秒
      leaveCountDown: null,
      codeUrl: '',
      interval: null,
      paySucceedInterval: null,
      msg: '',
      oldTime: new Date().getTime()
    }
  },
  computed: {
    countdownSecond() {
      return (this.countdown / 1000).toFixed(0)
    }
  },
  created() {
    this.getPurchase()
    // 添加监听事件
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    handleVisiable: function (e) {
      if (e.target.visibilityState == 'visible') {
        this.leaveCountDown = (new Date().getTime() - this.oldTime).toFixed(0)
        if (this.leaveCountDown >= 120000) {
          // 离开的时间大于2分钟
          this.countdown = 0
        } else {
          this.countdown = 120000 - this.leaveCountDown
          this.startInterval()
        }
      } else {
        clearInterval(this.interval)
        clearInterval(this.paySucceedInterval)
      }
    },
    async getPurchase() {
      try {
        let params = {
          orderNo: this.orderNo,
          terminalType: this.terminalType,
          payWay: this.payWay, // 0:支付宝 1 微信
          mode: 'native' // native 、app、h5，对应二维码、手机App和h5网页
        }
        let res = await orderPayV2(params)
        this.countdown = res.data.countdown
        // this.countdown = 10000
        this.codeUrl = res.data.codeUrl
        this.startInterval()
        // if (this.interval) {
        //   clearInterval(this.interval)
        // }
        // // 解决1秒的时间差问题
        // this.countdown = this.countdown - 1000
        // this.interval = setInterval(() => {
        //   this.countdown = this.countdown - 1000
        //   if (this.countdown <= 0) {
        //     clearInterval(this.interval)
        //   }
        // }, 1000) //1000毫秒后执行

        // if (this.paySucceedInterval) {
        //   clearInterval(this.paySucceedInterval)
        // }
        // this.paySucceedInterval = setInterval(() => {
        //   this.getCheckPay()
        //   if (this.countdown == 0) {
        //     // 清空定时器 结束循环调用
        //     clearInterval(this.paySucceedInterval)
        //   }
        // }, 5000)
        // // 页面离开清空定时器
        // this.$once('hook:beforeDestroy', () => {
        //   clearInterval(this.interval)
        //   clearInterval(this.paySucceedInterval)
        //   console.log('离开')
        // })
      } catch (error) {
        this.msg = error.msg
      }
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }
      // 解决1秒的时间差问题
      this.countdown = this.countdown - 1000
      this.interval = setInterval(() => {
        this.countdown = this.countdown - 1000
        if (this.countdown <= 0) {
          clearInterval(this.interval)
        }
      }, 1000) //1000毫秒后执行
      if (this.paySucceedInterval) {
        clearInterval(this.paySucceedInterval)
      }
      this.paySucceedInterval = setInterval(() => {
        this.getCheckPay()
        if (this.countdown == 0) {
          // 清空定时器 结束循环调用
          clearInterval(this.paySucceedInterval)
        }
      }, 5000)
      // 页面离开清空定时器
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.interval)
        clearInterval(this.paySucceedInterval)
        console.log('离开')
      })
    },
    // 查询该订单是否支付成功
    async getCheckPay() {
      try {
        let res = await checkPay(this.orderId)
        if (res.msg == '已支付') {
          // 清空定时器 结束循环调用
          clearInterval(this.paySucceedInterval)
          this.$router.replace('/paySucceed')
        }
      } catch (error) {}
    },
    onCancel() {
      this.$router.go(-1)
    },
    onRefreshCode() {
      this.oldTime = new Date().getTime()
      this.getPurchase()
    },
    goCourseDetails() {
      this.$router.go(-1)
    },
    lookCourse() {
      this.$router.replace('/personalCenter')
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  }
}
</script>
<style lang="scss" scoped>
.web-pay {
  box-sizing: border-box;
  height: calc(100vh - 190px);
  min-height: 747px;
  margin: auto;
  margin-top: $robin-navbar-height-web;
  border-radius: 10px 10px 0px 0px;
  background: #f8f8f8;
  .header {
    width: 1200px;
    height: 83px;
    line-height: 83px;
    margin: auto;
    background: #f8f8f8;
    padding: 0 23px;
    position: relative;
    img {
      position: absolute;
      width: 9px;
      height: 17px;
      top: 50%;
      margin-top: -8px;
      left: 23px;
      cursor: pointer;
    }
    div {
      height: 83px;
      line-height: 83px;
      font-weight: bold;
      color: #2b2f36;
      text-align: left;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .content {
    width: 1200px;
    background: #fff;
    height: calc(100% - 140px);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    position: relative;
    .content-words {
      font-size: 18px;
      font-weight: 400;
      color: #8e8e93;
    }
    &-time {
      color: #ff5500;
      font-size: 18px;
      span {
        font-size: 36px;
        font-weight: 400;
        color: #ff5500;
      }
    }
    &-code {
      box-sizing: border-box;
      width: 181px;
      height: 180px;
      padding: 2px;
    }
    &-refresh-code {
      position: absolute;
      top: 190px;
      width: 181px;
      height: 180px;
      line-height: 180px;
      text-align: center;
      background: rgb(0, 0, 0, 0.9);
      color: #fff;
    }
    &-hint {
      font-size: 14px;
      font-weight: 400;
      color: #8e8e93;
      span {
        color: #ff5500;
      }
    }
    &-cancle {
      width: 162px;
      height: 45px;
      line-height: 45px;
      border-radius: 23px;
      border: 1px solid #ff5500;
      font-size: 14px;
      font-weight: 400;
      color: #ff5500;
      text-align: center;
      margin-top: 36px;
      cursor: pointer;
    }
    &-refresh {
      width: 120px;
      height: 38px;
      line-height: 38px;
      background: #ff5500;
      text-align: center;
      border-radius: 23px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 24px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .msg {
    width: 1200px;
    background: #fff;
    height: calc(100% - 140px);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    color: #8e8e93;
  }
}
</style>
