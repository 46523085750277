import request from '@/utils/request'
// 获取课程详情
export function getCourseDetail(params) {
  return request({
    url: `train/course/detail`,
    method: 'GET',
    params,
    headers: {
      isToken: false // 不加token
    }
  })
}

// 查询企业账号
export function getComAccount() {
  return request({
    url: '/shopping/account/comAccount',
    method: 'get'
  })
}

// 获取企业内大屏数据
export function getScrUserList() {
  return request({
    url: '/system/sysAppUser/scrUserList',
    method: 'get'
  })
}

// 兑换券列表
export function getVoucherState(groupId, state) {
  return request({
    url: `/shopping/voucherState/orderVouchers/${groupId}/${state}`,
    method: 'get'
  })
}

// 下单接口---最新
export function toplaceV2(data) {
  return request({
    url: `shopping/orderPay/toplace`,
    method: 'POST',
    data
  })
}
// 支付接口
export function orderPayV2(data) {
  return request({
    url: `shopping/orderPay`,
    method: 'POST',
    data
  })
}

// 支付接口---兑换券
export function orderPayVouch(data) {
  return request({
    url: `/shopping/orderPay/vouch`,
    method: 'POST',
    data
  })
}

// 查询是否支付完成
export function checkPay(id) {
  return request({
    url: `shopping/orderPay/check/${id}`,
    method: 'GET'
  })
}
// 根据订单号查询订单详情
export function getOrderDetail(orderNum) {
  return request({
    url: `shopping/order/query/${orderNum}`,
    method: 'GET'
  })
}

// 获取支付密码错误次数
export function payPassErrorCount() {
  return request({
    url: '/shopping/orderPay/payPassErrorCount',
    method: 'get'
  })
}

// 获取验证码
export function getSmsCode(params) {
  return request({
    url: `/auth/getSmsCode`,
    method: 'get',
    params,
  })
}